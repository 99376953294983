<template>
<div>
    
    <qr-code 
    text="https://www.thefixit.net/fixit.vcf"
    size="250"
    color="#000"
    bg-color="#fff" 
    error-level="L">
</qr-code>
</div>
</template>
<script>

 export default {
    components: {
      
    }
 }   
</script>